/*
Language: Gradle
Description: Gradle is an open-source build automation tool focused on flexibility and performance.
Website: https://gradle.org
Author: Damian Mee <mee.damian@gmail.com>
Category: build-system
*/

function gradle(hljs) {
  const KEYWORDS = ["task", "project", "allprojects", "subprojects", "artifacts", "buildscript", "configurations", "dependencies", "repositories", "sourceSets", "description", "delete", "from", "into", "include", "exclude", "source", "classpath", "destinationDir", "includes", "options", "sourceCompatibility", "targetCompatibility", "group", "flatDir", "doLast", "doFirst", "flatten", "todir", "fromdir", "ant", "def", "abstract", "break", "case", "catch", "continue", "default", "do", "else", "extends", "final", "finally", "for", "if", "implements", "instanceof", "native", "new", "private", "protected", "public", "return", "static", "switch", "synchronized", "throw", "throws", "transient", "try", "volatile", "while", "strictfp", "package", "import", "false", "null", "super", "this", "true", "antlrtask", "checkstyle", "codenarc", "copy", "boolean", "byte", "char", "class", "double", "float", "int", "interface", "long", "short", "void", "compile", "runTime", "file", "fileTree", "abs", "any", "append", "asList", "asWritable", "call", "collect", "compareTo", "count", "div", "dump", "each", "eachByte", "eachFile", "eachLine", "every", "find", "findAll", "flatten", "getAt", "getErr", "getIn", "getOut", "getText", "grep", "immutable", "inject", "inspect", "intersect", "invokeMethods", "isCase", "join", "leftShift", "minus", "multiply", "newInputStream", "newOutputStream", "newPrintWriter", "newReader", "newWriter", "next", "plus", "pop", "power", "previous", "print", "println", "push", "putAt", "read", "readBytes", "readLines", "reverse", "reverseEach", "round", "size", "sort", "splitEachLine", "step", "subMap", "times", "toInteger", "toList", "tokenize", "upto", "waitForOrKill", "withPrintWriter", "withReader", "withStream", "withWriter", "withWriterAppend", "write", "writeLine"];
  return {
    name: 'Gradle',
    case_insensitive: true,
    keywords: KEYWORDS,
    contains: [hljs.C_LINE_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE, hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE, hljs.NUMBER_MODE, hljs.REGEXP_MODE]
  };
}
export { gradle as default };