import Embed from 'quill/blots/embed'
import { generateUUID } from '../core/utils/app-util'
import Quill from 'quill'
import Scroll from 'quill/blots/scroll'

export class FormatAnchorItalic extends Embed {
  static override blotName = 'format-anchor-italic'
  static override tagName = 'div'

  static override create(value: boolean): HTMLElement {
    const node = super.create(value) as HTMLDivElement
    node.classList.add('ql-format-anchor')
    node.classList.add('d-inline-block')
    node.innerText = '__'
    node.setAttribute('contenteditable', 'false')
    return node
  }
}

export type ReplaceablePlaceholderType =
  | 'placeholder'
  | 'dc-user-mention'
  | 'dc-role-mention'
  | 'dc-channel'
  | 'dc-emoji'
  | 'dc-custom-emoji'

export interface ReplaceablePlaceholder {
  type: ReplaceablePlaceholderType
  matchRegex: RegExp
  matchRegexGlobal: RegExp
  acceptMatch: (match: string) => boolean
  embedDeserialize: (match: string) => FormatPlaceholderData
  embedSerialize: (data: FormatPlaceholderData) => string
}

export interface FormatPlaceholderData {
  type: ReplaceablePlaceholderType
  value: string
  real_value: string
  text_color?: string
  suggestion_name?: string
  suggestion_description?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customEmojiId?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customEmojiAnimated?: boolean
  uuid?: string
}

export class FormatPlaceholder extends Embed {
  static override blotName = 'format-placeholder'
  static override tagName = 'span'

  static override create(value: FormatPlaceholderData): HTMLElement {
    const node = super.create(value) as HTMLSpanElement

    if (value.type === 'placeholder') {
      node.classList.add('ql-format-placeholder')
      node.classList.add('d-inline-block')
      node.innerText = value.value
      node.setAttribute('title', value.suggestion_description ?? '')
    } else if (value.type === 'dc-user-mention') {
      node.classList.add('ql-format-dc-user-mention')
      node.classList.add('d-inline-block')
      node.innerText = value.value
      node.setAttribute('title', 'id: ' + value.real_value)
    } else if (value.type === 'dc-role-mention') {
      node.classList.add('ql-format-dc-role-mention')
      node.classList.add('d-inline-block')
      node.innerText = value.value
      node.setAttribute('title', 'id: ' + value.real_value)
    } else if (value.type === 'dc-channel') {
      node.classList.add('ql-format-dc-channel')
      node.classList.add('d-inline-block')
      node.innerText = value.value
      node.setAttribute('title', 'id: ' + value.real_value)
    } else if (value.type === 'dc-emoji') {
      node.classList.add('ql-format-dc-emoji')
      node.classList.add('d-inline-block')
      node.innerHTML = twemoji.parse(value.value, {
        folder: 'svg',
        ext: '.svg'
      })
      node.setAttribute('title', value.real_value ?? '')
    } else if (value.type === 'dc-custom-emoji') {
      const animated =
        (value.customEmojiAnimated as unknown as string) == 'true' ||
        value.customEmojiAnimated === true
      const id = value.customEmojiId ?? ''
      node.classList.add('ql-format-dc-custom-emoji')
      node.classList.add('d-inline-block')
      node.innerHTML =
        '<img class="emoji" src="https://cdn.discordapp.com/emojis/' +
        id +
        (animated ? '.gif' : '.png') +
        '" />'
      node.setAttribute('title', value.real_value ?? '')
    }

    const randomID = generateUUID()
    node.setAttribute('qj-uuid', randomID)
    node.setAttribute('qj-value', value.value)
    node.setAttribute('qj-real_value', value.real_value)
    node.setAttribute('qj-type', value.type)
    node.setAttribute('qj-suggestion_name', value.suggestion_name ?? '')
    node.style.cursor = 'default'

    if (value.customEmojiId !== undefined) {
      node.setAttribute('qj-custom_emoji_id', value.customEmojiId)
    }
    if (value.customEmojiAnimated !== undefined) {
      node.setAttribute(
        'qj-custom_emoji_animated',
        value.customEmojiAnimated ? 'true' : 'false'
      )
    }

    if (value.text_color !== undefined) {
      node.setAttribute('qj-text_color', value.text_color)
      node.style.color = value.text_color
    }

    node.onclick = (event: MouseEvent) => {
      const span = event.target as HTMLSpanElement
      const editorNode = span.closest('.ql-editor')
      if (editorNode !== null) {
        const quill = Quill.find(editorNode)
        if (quill instanceof Scroll) {
          const quillReal = Quill.find(
            quill.scroll.domNode.parentElement!
          ) as Quill
          const blotDomNode = document.querySelector(`[qj-uuid="${randomID}"]`)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (blotDomNode !== null) {
            const blotInstance = Quill.find(blotDomNode)
            if (blotInstance) {
              // Get the index of the blot in the editor
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const blotIndex = quillReal.getIndex(blotInstance as any)
              // Crear selección...
              const nodeX = node.getBoundingClientRect().x
              const nodeWidth = node.getBoundingClientRect().width
              const nodeMiddle = nodeX + nodeWidth / 2
              quillReal.setSelection({
                index: blotIndex + (nodeMiddle < event.x ? 1 : 0),
                length: 0
              })
            }
          }
        }
      }
    }

    node.ondblclick = (event: MouseEvent) => {
      const span = event.target as HTMLSpanElement
      const editorNode = span.closest('.ql-editor')
      if (editorNode !== null) {
        const quill = Quill.find(editorNode)
        if (quill instanceof Scroll) {
          const quillReal = Quill.find(
            quill.scroll.domNode.parentElement!
          ) as Quill
          const blotDomNode = document.querySelector(`[qj-uuid="${randomID}"]`)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (blotDomNode !== null) {
            const blotInstance = Quill.find(blotDomNode)
            if (blotInstance) {
              // Get the index of the blot in the editor
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const blotIndex = quillReal.getIndex(blotInstance as any)
              // Crear selección...
              quillReal.setSelection({
                index: blotIndex,
                length: 1
              })
            }
          }
        }
      }
    }

    setTimeout(() => {
      new bootstrap.Tooltip(node)
      node.setAttribute('data-bs-toggle', 'tooltip')
      node.setAttribute('data-bs-placement', 'top')
    }, 0)

    return node
  }

  static override value(node: HTMLElement): FormatPlaceholderData {
    //const childNotEditable = node.childNodes[1] as HTMLElement
    const child = node //.childNodes[0] as HTMLElement
    return {
      uuid: child.getAttribute('qj-uuid') || '',
      value: child.getAttribute('qj-value') || '',
      real_value: child.getAttribute('qj-real_value') || '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: (child.getAttribute('qj-type') as any) || 'placeholder',
      text_color: child.hasAttribute('qj-text_color')
        ? child.getAttribute('qj-text_color') || undefined
        : undefined,
      suggestion_name: child.getAttribute('qj-suggestion_name') || '',
      customEmojiId: child.getAttribute('qj-custom_emoji_id') || undefined,
      customEmojiAnimated:
        child.getAttribute('qj-custom_emoji_animated') == 'true' ? true : false
    }
  }

  /**
   * Redefine the `update` method to handle the `childList` case.
   * This is necessary to correctly handle "backspace" on Android using Gboard.
   * It behaves differently than other cases and we need to handle the node
   * removal instead of the `characterData`.
   */
  override update(
    mutations: MutationRecord[],
    context: Record<string, unknown>
  ): void {
    mutations.forEach((mutation) => {
      if (
        mutation.type === 'childList' &&
        (Array.from(mutation.removedNodes).includes(this.leftGuard) ||
          Array.from(mutation.removedNodes).includes(this.rightGuard))
      ) {
        let tag
        if (mutation.previousSibling) {
          tag = (mutation.previousSibling as HTMLElement).innerText
        } else if (mutation.nextSibling) {
          tag = (mutation.nextSibling as HTMLElement).innerText
        }
        if (tag) {
          super.replaceWith('text', tag)
        }
      }
    })

    super.update(mutations, context)
  }
}
