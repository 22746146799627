import Quill, { Delta } from 'quill/core'
import Clipboard from 'quill/modules/clipboard'
import type { Range } from 'quill/core/selection'
import { ReplaceablePlaceholderType } from './format-anchor'
import { EditorComponent } from '../core/components/utils/editor/editor.component'
import { retrieveEmojiUnicodeByShortName } from '../core/utils/app-util'

class PlainClipboard extends Clipboard {
  startAdding = false

  override onPaste(
    range: Range,
    {
      text,
      html
    }: {
      text?: string
      html?: string
    }
  ) {
    const delta = new Delta().retain(range.index).delete(range.length)
    const offsetToSet = [0]

    if (html !== undefined && html.trim().length > 0) {
      // ¿Vale la pena formatarlo? Si no, no lo intentamos!!
      if (!html.includes('<span class="ql-format')) {
        if (text !== undefined) {
          delta.insert(text)
          offsetToSet[0] += text.length
        }
      } else {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')

        const editor = EditorComponent.getEditorInstanceByTarget(
          this.quill.container
        )

        this.loopNode(delta, doc.body, editor, offsetToSet)
      }
    } else if (text !== undefined) {
      delta.insert(text)
      offsetToSet[0] += text.length
    }

    this.quill.updateContents(delta, Quill.sources.USER)
    setTimeout(() => {
      this.quill.setSelection(
        range.index + offsetToSet[0] - range.length,
        Quill.sources.USER
      )
    }, 0)
    this.quill.scrollSelectionIntoView()
  }

  loopNode(
    delta: Delta,
    node: Node,
    editor: EditorComponent | null,
    offsetToSet: number[]
  ) {
    node.childNodes.forEach((el) => {
      if (el.nodeType === Node.COMMENT_NODE) {
        const data = (el as Comment).data
        if (data === 'StartFragment') {
          this.startAdding = true
        } else if (data === 'EndFragment') {
          this.startAdding = false
        }
      } else if (el.nodeType === Node.TEXT_NODE && this.startAdding) {
        if (el.textContent?.length !== 0) {
          delta.insert(el.textContent ?? '')
          offsetToSet[0] += (el.textContent ?? '').length
        }
      } else if (this.startAdding) {
        const element = el as Element
        if (element.tagName === 'BR') {
          delta.insert('\n')
          offsetToSet[0] += 1
          return
        }
        if (element.tagName === 'SPAN') {
          const span = el as HTMLSpanElement
          const qjValue = span.getAttribute('qj-value')
          const qjRealValue = span.getAttribute('qj-real_value')
          const qjType = span.getAttribute(
            'qj-type'
          ) as ReplaceablePlaceholderType | null
          const qjCustomEmojiId = span.getAttribute('qj-custom_emoji_id')
          const qjCustomEmojiAnimated = span.getAttribute(
            'qj-custom_emoji_animated'
          )

          if (qjValue === null || qjRealValue === null || qjType === null) {
            this.loopNode(delta, el, editor, offsetToSet)
            return
          }

          if (
            qjType !== null &&
            editor !== null &&
            ((qjType === 'dc-channel' && !editor.allowChannelMention) ||
              (qjType === 'dc-user-mention' && !editor.allowUserMention) ||
              (qjType === 'dc-role-mention' && !editor.allowRoleMention) ||
              (qjType === 'dc-custom-emoji' && !editor.allowCustomEmojis) ||
              (qjType === 'dc-emoji' && !editor.allowUnicodeEmojis))
          ) {
            switch (qjType) {
              case 'dc-channel': {
                const val = '<#' + qjRealValue + '>'
                delta.insert(val)
                offsetToSet[0] += val.length
                break
              }
              case 'dc-user-mention': {
                const val = '<@' + qjRealValue + '>'
                delta.insert(val)
                offsetToSet[0] += val.length
                break
              }
              case 'dc-role-mention': {
                const val = '<@&' + qjRealValue + '>'
                delta.insert(val)
                offsetToSet[0] += val.length
                break
              }
              case 'dc-custom-emoji':
                delta.insert(qjRealValue)
                offsetToSet[0] += qjRealValue.length
                break
              case 'dc-emoji': {
                const unicode = retrieveEmojiUnicodeByShortName(
                  qjRealValue.replaceAll(':', '')
                )
                if (unicode === null) {
                  delta.insert(qjRealValue)
                  offsetToSet[0] += qjRealValue.length
                } else {
                  delta.insert(unicode)
                  offsetToSet[0] += unicode.length
                }
                break
              }
            }
          } else {
            if (editor != null && qjType !== null) {
              // Verificar si el editor puede procesar esto
              const toInsert = editor.handleCopiedPlaceholder(
                qjType,
                qjValue,
                qjRealValue,
                qjCustomEmojiId,
                qjCustomEmojiAnimated
              )
              if (toInsert === undefined) {
                delta.insert(qjRealValue)
                offsetToSet[0] += qjRealValue.length
              } else {
                delta.insert({
                  'format-placeholder': toInsert
                })
                offsetToSet[0] += 1
              }
            } else {
              delta.insert(qjRealValue)
              offsetToSet[0] += qjRealValue.length
            }
          }
        } else {
          this.loopNode(delta, el, editor, offsetToSet)
        }
      }
    })

    const nodeElement = node as Element
    if (
      nodeElement.tagName === 'LI' ||
      nodeElement.tagName === 'P' ||
      nodeElement.tagName === 'BR' ||
      nodeElement.tagName === 'DIV' ||
      nodeElement.tagName === 'HR' ||
      nodeElement.tagName === 'H1' ||
      nodeElement.tagName === 'H2' ||
      nodeElement.tagName === 'H3' ||
      nodeElement.tagName === 'H4' ||
      nodeElement.tagName === 'H5' ||
      nodeElement.tagName === 'H6'
    ) {
      delta.insert('\n')
      offsetToSet[0] += 1
    }
  }
}

export default PlainClipboard
