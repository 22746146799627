import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { Observable, retry, tap, timer } from 'rxjs'
import { getZerGroshBackendUrl } from '../../services/zergrosh.service'
import { LoginService } from '../../services/login.service'

@Injectable()
export class ZerGroshHttpInterceptor implements HttpInterceptor {
  firstIntercept = true

  constructor(private injector: Injector) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith(getZerGroshBackendUrl())) {
      const login = this.injector.get(LoginService)
      const deviceId = login.getDeviceId()
      if (login.loginData.value.zergroshAccessToken !== undefined) {
        let headers = req.headers
          .set(
            'Authorization',
            'Bearer ' + login.loginData.value.zergroshAccessToken
          )
          .set(
            'Z-Authorization',
            login.loginData.value.discord?.accessToken ?? ''
          )
          .set('Z-Device-Id', deviceId)

        if (!headers.has('Content-Type') && req.body) {
          if (!(req.body instanceof FormData)) {
            headers = headers.set('Content-Type', 'application/json')
          }
        }

        const authReq = req.clone({
          headers: headers
        })

        const handler = next.handle(authReq).pipe(
          tap({
            error: (err: HttpErrorResponse) => {
              if (err.status == 403 && !req.url.includes('discord/logout')) {
                login.logout(null, true)
              }
            }
          }),
          retry({
            delay: (error, retryIndex) => {
              if (retryIndex === 10) {
                throw error
              }

              if (error instanceof HttpErrorResponse) {
                if (error.status === 429) {
                  const retryAfter = error.headers.get('Retry-After')
                  if (retryAfter !== null) {
                    return timer(parseInt(retryAfter) * 1000 + 1000)
                  }
                }
              }

              throw error
            }
          })
        )
        return handler
      } else {
        let headers = req.headers.set('Z-Device-Id', deviceId)

        if (!headers.has('Content-Type') && req.body) {
          if (!(req.body instanceof FormData)) {
            headers = headers.set('Content-Type', 'application/json')
          }
        }

        const authReq = req.clone({
          headers: headers
        })

        const handler = next.handle(authReq)
        return handler
      }
    }

    const handler = next.handle(req).pipe(
      retry({
        delay: (error, retryIndex) => {
          if (retryIndex === 10) {
            throw error
          }

          if (error instanceof HttpErrorResponse) {
            if (error.status === 429) {
              const retryAfter = error.headers.get('Retry-After')
              if (retryAfter !== null) {
                return timer(parseInt(retryAfter) * 1000 + 1000)
              }
            }
          }

          throw error
        }
      })
    )

    return handler
  }
}
