import {
  AfterContentInit,
  AfterViewInit,
  Component,
  HostListener,
  OnInit
} from '@angular/core'
import { LoginService } from './core/services/login.service'
import { LocaleService } from './core/services/locale.service'
import { LoaderService } from './core/services/loader.service'
import Quill from 'quill/core'
import { FormatAnchorItalic, FormatPlaceholder } from './quilljs/format-anchor'
import PlainClipboard from './quilljs/clipboard-module'
import 'quill-mention'
import moment from 'moment'
import * as Sentry from '@sentry/angular'

// Idiomas de moment js
import 'moment/locale/es'
import 'moment/locale/fr'
import { environment } from 'src/environments/environment'
import 'quill-mention/autoregister'
import hljs from 'highlight.js/lib/common'
import java from 'highlight.js/lib/languages/java'
import perl from 'highlight.js/lib/languages/perl'
import bash from 'highlight.js/lib/languages/bash'
import cpp from 'highlight.js/lib/languages/cpp'
import csharp from 'highlight.js/lib/languages/csharp'
import css from 'highlight.js/lib/languages/css'
import diff from 'highlight.js/lib/languages/diff'
import go from 'highlight.js/lib/languages/go'
import graphql from 'highlight.js/lib/languages/graphql'
import ini from 'highlight.js/lib/languages/ini'
import js from 'highlight.js/lib/languages/javascript'
import json from 'highlight.js/lib/languages/json'
import kotlin from 'highlight.js/lib/languages/kotlin'
import less from 'highlight.js/lib/languages/less'
import lua from 'highlight.js/lib/languages/lua'
import makefile from 'highlight.js/lib/languages/makefile'
import markdown from 'highlight.js/lib/languages/markdown'
import objectivec from 'highlight.js/lib/languages/objectivec'
import php from 'highlight.js/lib/languages/php'
import phpTemplate from 'highlight.js/lib/languages/php-template'
import python from 'highlight.js/lib/languages/python'
import pythonRepl from 'highlight.js/lib/languages/python-repl'
import r from 'highlight.js/lib/languages/r'
import ruby from 'highlight.js/lib/languages/ruby'
import rust from 'highlight.js/lib/languages/rust'
import scss from 'highlight.js/lib/languages/scss'
import shell from 'highlight.js/lib/languages/shell'
import sql from 'highlight.js/lib/languages/sql'
import swift from 'highlight.js/lib/languages/swift'
import typescript from 'highlight.js/lib/languages/typescript'
import vbnet from 'highlight.js/lib/languages/vbnet'
import wasm from 'highlight.js/lib/languages/wasm'
import xml from 'highlight.js/lib/languages/xml'
import yaml from 'highlight.js/lib/languages/yaml'
import armasm from 'highlight.js/lib/languages/armasm'
import avrasm from 'highlight.js/lib/languages/avrasm'
import llvm from 'highlight.js/lib/languages/llvm'
import mipsasm from 'highlight.js/lib/languages/mipsasm'
import x86asm from 'highlight.js/lib/languages/x86asm'
import apache from 'highlight.js/lib/languages/apache'
import crmsh from 'highlight.js/lib/languages/crmsh'
import dns from 'highlight.js/lib/languages/dns'
import dockerfile from 'highlight.js/lib/languages/dockerfile'
import dsconfig from 'highlight.js/lib/languages/dsconfig'
import dts from 'highlight.js/lib/languages/dts'
import jbossCli from 'highlight.js/lib/languages/jboss-cli'
import ldif from 'highlight.js/lib/languages/ldif'
import nestedtext from 'highlight.js/lib/languages/nestedtext'
import nginx from 'highlight.js/lib/languages/nginx'
import pf from 'highlight.js/lib/languages/pf'
import properties from 'highlight.js/lib/languages/properties'
import puppet from 'highlight.js/lib/languages/puppet'
import roboconf from 'highlight.js/lib/languages/roboconf'
import stylus from 'highlight.js/lib/languages/stylus'
import e1c from 'highlight.js/lib/languages/1c'
import axapta from 'highlight.js/lib/languages/axapta'
import cos from 'highlight.js/lib/languages/cos'
import isbl from 'highlight.js/lib/languages/isbl'
import livecodeserver from 'highlight.js/lib/languages/livecodeserver'
import ruleslanguage from 'highlight.js/lib/languages/ruleslanguage'
import clean from 'highlight.js/lib/languages/clean'
import coq from 'highlight.js/lib/languages/coq'
import elixir from 'highlight.js/lib/languages/elixir'
import elm from 'highlight.js/lib/languages/elm'
import erlang from 'highlight.js/lib/languages/erlang'
import erlangRepl from 'highlight.js/lib/languages/erlang-repl'
import flix from 'highlight.js/lib/languages/flix'
import fsharp from 'highlight.js/lib/languages/fsharp'
import haskell from 'highlight.js/lib/languages/haskell'
import ocaml from 'highlight.js/lib/languages/ocaml'
import reasonml from 'highlight.js/lib/languages/reasonml'
import scala from 'highlight.js/lib/languages/scala'
import sml from 'highlight.js/lib/languages/sml'
import xquery from 'highlight.js/lib/languages/xquery'
import glsl from 'highlight.js/lib/languages/glsl'
import mel from 'highlight.js/lib/languages/mel'
import processing from 'highlight.js/lib/languages/processing'
import rib from 'highlight.js/lib/languages/rib'
import rsl from 'highlight.js/lib/languages/rsl'
import clojure from 'highlight.js/lib/languages/clojure'
import clojureRepl from 'highlight.js/lib/languages/clojure-repl'
import hy from 'highlight.js/lib/languages/hy'
import lisp from 'highlight.js/lib/languages/lisp'
import scheme from 'highlight.js/lib/languages/scheme'
import accesslog from 'highlight.js/lib/languages/accesslog'
import asciidoc from 'highlight.js/lib/languages/asciidoc'
import latex from 'highlight.js/lib/languages/latex'
import capnproto from 'highlight.js/lib/languages/capnproto'
import http from 'highlight.js/lib/languages/http'
import protobuf from 'highlight.js/lib/languages/protobuf'
import thrift from 'highlight.js/lib/languages/thrift'
import fortran from 'highlight.js/lib/languages/fortran'
import gams from 'highlight.js/lib/languages/gams'
import gauss from 'highlight.js/lib/languages/gauss'
import irpf90 from 'highlight.js/lib/languages/irpf90'
import mathematica from 'highlight.js/lib/languages/mathematica'
import matlab from 'highlight.js/lib/languages/matlab'
import maxima from 'highlight.js/lib/languages/maxima'
import mizar from 'highlight.js/lib/languages/mizar'
import openscad from 'highlight.js/lib/languages/openscad'
import scilab from 'highlight.js/lib/languages/scilab'
import stan from 'highlight.js/lib/languages/stan'
import stata from 'highlight.js/lib/languages/stata'
import actionscript from 'highlight.js/lib/languages/actionscript'
import angelscript from 'highlight.js/lib/languages/angelscript'
import applescript from 'highlight.js/lib/languages/applescript'
import arcade from 'highlight.js/lib/languages/arcade'
import autohotkey from 'highlight.js/lib/languages/autohotkey'
import autoit from 'highlight.js/lib/languages/autoit'
import coffeescript from 'highlight.js/lib/languages/coffeescript'
import dart from 'highlight.js/lib/languages/dart'
import gml from 'highlight.js/lib/languages/gml'
import hsp from 'highlight.js/lib/languages/hsp'
import javascript from 'highlight.js/lib/languages/javascript'
import livescript from 'highlight.js/lib/languages/livescript'
import lsl from 'highlight.js/lib/languages/lsl'
import moonscript from 'highlight.js/lib/languages/moonscript'
import nodeRepl from 'highlight.js/lib/languages/node-repl'
import qml from 'highlight.js/lib/languages/qml'
import sqf from 'highlight.js/lib/languages/sqf'
import vbscript from 'highlight.js/lib/languages/vbscript'
import vbscriptHtml from 'highlight.js/lib/languages/vbscript-html'
import vim from 'highlight.js/lib/languages/vim'
import wren from 'highlight.js/lib/languages/wren'
import nim from 'highlight.js/lib/languages/nim'
import django from 'highlight.js/lib/languages/django'
import dust from 'highlight.js/lib/languages/dust'
import erb from 'highlight.js/lib/languages/erb'
import haml from 'highlight.js/lib/languages/haml'
import handlebars from 'highlight.js/lib/languages/handlebars'
import mojolicious from 'highlight.js/lib/languages/mojolicious'
import parser3 from 'highlight.js/lib/languages/parser3'
import twig from 'highlight.js/lib/languages/twig'
import abnf from 'highlight.js/lib/languages/abnf'
import ada from 'highlight.js/lib/languages/ada'
import arduino from 'highlight.js/lib/languages/arduino'
import aspectj from 'highlight.js/lib/languages/aspectj'
import awk from 'highlight.js/lib/languages/awk'
import basic from 'highlight.js/lib/languages/basic'
import bnf from 'highlight.js/lib/languages/bnf'
import brainfuck from 'highlight.js/lib/languages/brainfuck'
import cal from 'highlight.js/lib/languages/cal'
import ceylon from 'highlight.js/lib/languages/ceylon'
import cmake from 'highlight.js/lib/languages/cmake'
import crystal from 'highlight.js/lib/languages/crystal'
import csp from 'highlight.js/lib/languages/csp'
import d from 'highlight.js/lib/languages/d'
import delphi from 'highlight.js/lib/languages/delphi'
import dos from 'highlight.js/lib/languages/dos'
import ebnf from 'highlight.js/lib/languages/ebnf'
import excel from 'highlight.js/lib/languages/excel'
import fix from 'highlight.js/lib/languages/fix'
import gcode from 'highlight.js/lib/languages/gcode'
import gherkin from 'highlight.js/lib/languages/gherkin'
import golo from 'highlight.js/lib/languages/golo'
import gradle from 'highlight.js/lib/languages/gradle'
import groovy from 'highlight.js/lib/languages/groovy'
import haxe from 'highlight.js/lib/languages/haxe'
import inform7 from 'highlight.js/lib/languages/inform7'
import julia from 'highlight.js/lib/languages/julia'
import juliaRepl from 'highlight.js/lib/languages/julia-repl'
import lasso from 'highlight.js/lib/languages/lasso'
import leaf from 'highlight.js/lib/languages/leaf'
import mercury from 'highlight.js/lib/languages/mercury'
import monkey from 'highlight.js/lib/languages/monkey'
import n1ql from 'highlight.js/lib/languages/n1ql'
import nix from 'highlight.js/lib/languages/nix'
import nsis from 'highlight.js/lib/languages/nsis'
import oxygene from 'highlight.js/lib/languages/oxygene'
import pgsql from 'highlight.js/lib/languages/pgsql'
import pony from 'highlight.js/lib/languages/pony'
import powershell from 'highlight.js/lib/languages/powershell'
import profile from 'highlight.js/lib/languages/profile'
import prolog from 'highlight.js/lib/languages/prolog'
import purebasic from 'highlight.js/lib/languages/purebasic'
import q from 'highlight.js/lib/languages/q'
import routeros from 'highlight.js/lib/languages/routeros'
import sas from 'highlight.js/lib/languages/sas'
import smali from 'highlight.js/lib/languages/smali'
import smalltalk from 'highlight.js/lib/languages/smalltalk'
import step21 from 'highlight.js/lib/languages/step21'
import subunit from 'highlight.js/lib/languages/subunit'
import taggerscript from 'highlight.js/lib/languages/taggerscript'
import tap from 'highlight.js/lib/languages/tap'
import tcl from 'highlight.js/lib/languages/tcl'
import tp from 'highlight.js/lib/languages/tp'
import vala from 'highlight.js/lib/languages/vala'
import verilog from 'highlight.js/lib/languages/verilog'
import vhdl from 'highlight.js/lib/languages/vhdl'
import xl from 'highlight.js/lib/languages/xl'
import zephir from 'highlight.js/lib/languages/zephir'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit, AfterViewInit {
  title = 'zergrosh-front-v2'
  performingAction = true

  windowWidth: number = 0

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth
  }

  constructor(
    private loginService: LoginService,
    private loaderService: LoaderService,
    locale: LocaleService
  ) {
    locale.load()

    if (!environment.production) {
      Sentry.captureException(
        new Error('ERROR QUE NO SE DEBE CAPTURAR A SENTRY POR SER AMBIENTE DEV')
      )
    }
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth
    this.performingAction = this.loaderService.getDisplay()

    Quill.register(FormatAnchorItalic)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Quill.register(FormatPlaceholder as any)
    Quill.register(
      {
        'modules/clipboard': PlainClipboard
      },
      true
    )

    hljs.registerLanguage('bash', bash)
    hljs.registerLanguage('cpp', cpp)
    hljs.registerLanguage('csharp', csharp)
    hljs.registerLanguage('css', css)
    hljs.registerLanguage('diff', diff)
    hljs.registerLanguage('go', go)
    hljs.registerLanguage('graphql', graphql)
    hljs.registerLanguage('ini', ini)
    hljs.registerLanguage('java', java)
    hljs.registerLanguage('js', js)
    hljs.registerLanguage('json', json)
    hljs.registerLanguage('kotlin', kotlin)
    hljs.registerLanguage('less', less)
    hljs.registerLanguage('lua', lua)
    hljs.registerLanguage('makefile', makefile)
    hljs.registerLanguage('markdown', markdown)
    hljs.registerLanguage('objectivec', objectivec)
    hljs.registerLanguage('perl', perl)
    hljs.registerLanguage('php', php)
    hljs.registerLanguage('php-template', phpTemplate)
    hljs.registerLanguage('python', python)
    hljs.registerLanguage('python-repl', pythonRepl)
    hljs.registerLanguage('r', r)
    hljs.registerLanguage('ruby', ruby)
    hljs.registerLanguage('rust', rust)
    hljs.registerLanguage('scss', scss)
    hljs.registerLanguage('shell', shell)
    hljs.registerLanguage('sql', sql)
    hljs.registerLanguage('swift', swift)
    hljs.registerLanguage('typescript', typescript)
    hljs.registerLanguage('vbnet', vbnet)
    hljs.registerLanguage('wasm', wasm)
    hljs.registerLanguage('xml', xml)
    hljs.registerLanguage('yaml', yaml)
    hljs.registerLanguage('armasm', armasm)
    hljs.registerLanguage('avrasm', avrasm)
    hljs.registerLanguage('llvm', llvm)
    hljs.registerLanguage('mipsasm', mipsasm)
    hljs.registerLanguage('x86asm', x86asm)
    hljs.registerLanguage('apache', apache)
    hljs.registerLanguage('crmsh', crmsh)
    hljs.registerLanguage('dns', dns)
    hljs.registerLanguage('dockerfile', dockerfile)
    hljs.registerLanguage('dsconfig', dsconfig)
    hljs.registerLanguage('dts', dts)
    hljs.registerLanguage('jboss-cli', jbossCli)
    hljs.registerLanguage('ldif', ldif)
    hljs.registerLanguage('nestedtext', nestedtext)
    hljs.registerLanguage('nginx', nginx)
    hljs.registerLanguage('pf', pf)
    hljs.registerLanguage('properties', properties)
    hljs.registerLanguage('puppet', puppet)
    hljs.registerLanguage('roboconf', roboconf)
    hljs.registerLanguage('stylus', stylus)
    hljs.registerLanguage('1c', e1c)
    hljs.registerLanguage('axapta', axapta)
    hljs.registerLanguage('cos', cos)
    hljs.registerLanguage('isbl', isbl)
    hljs.registerLanguage('livecodeserver', livecodeserver)
    hljs.registerLanguage('ruleslanguage', ruleslanguage)
    hljs.registerLanguage('clean', clean)
    hljs.registerLanguage('coq', coq)
    hljs.registerLanguage('elixir', elixir)
    hljs.registerLanguage('elm', elm)
    hljs.registerLanguage('erlang', erlang)
    hljs.registerLanguage('erlang-repl', erlangRepl)
    hljs.registerLanguage('flix', flix)
    hljs.registerLanguage('fsharp', fsharp)
    hljs.registerLanguage('haskell', haskell)
    hljs.registerLanguage('ocaml', ocaml)
    hljs.registerLanguage('reasonml', reasonml)
    hljs.registerLanguage('scala', scala)
    hljs.registerLanguage('sml', sml)
    hljs.registerLanguage('xquery', xquery)
    hljs.registerLanguage('glsl', glsl)
    hljs.registerLanguage('mel', mel)
    hljs.registerLanguage('processing', processing)
    hljs.registerLanguage('rib', rib)
    hljs.registerLanguage('rsl', rsl)
    hljs.registerLanguage('clojure', clojure)
    hljs.registerLanguage('clojure-repl', clojureRepl)
    hljs.registerLanguage('hy', hy)
    hljs.registerLanguage('lisp', lisp)
    hljs.registerLanguage('scheme', scheme)
    hljs.registerLanguage('accesslog', accesslog)
    hljs.registerLanguage('asciidoc', asciidoc)
    hljs.registerLanguage('latex', latex)
    hljs.registerLanguage('capnproto', capnproto)
    hljs.registerLanguage('http', http)
    hljs.registerLanguage('protobuf', protobuf)
    hljs.registerLanguage('thrift', thrift)
    hljs.registerLanguage('fortran', fortran)
    hljs.registerLanguage('gams', gams)
    hljs.registerLanguage('gauss', gauss)
    hljs.registerLanguage('irpf90', irpf90)
    hljs.registerLanguage('mathematica', mathematica)
    hljs.registerLanguage('matlab', matlab)
    hljs.registerLanguage('maxima', maxima)
    hljs.registerLanguage('mizar', mizar)
    hljs.registerLanguage('openscad', openscad)
    hljs.registerLanguage('scilab', scilab)
    hljs.registerLanguage('stan', stan)
    hljs.registerLanguage('stata', stata)
    hljs.registerLanguage('actionscript', actionscript)
    hljs.registerLanguage('angelscript', angelscript)
    hljs.registerLanguage('applescript', applescript)
    hljs.registerLanguage('arcade', arcade)
    hljs.registerLanguage('autohotkey', autohotkey)
    hljs.registerLanguage('autoit', autoit)
    hljs.registerLanguage('coffeescript', coffeescript)
    hljs.registerLanguage('dart', dart)
    hljs.registerLanguage('gml', gml)
    hljs.registerLanguage('hsp', hsp)
    hljs.registerLanguage('javascript', javascript)
    hljs.registerLanguage('lsl', lsl)
    hljs.registerLanguage('moonscript', moonscript)
    hljs.registerLanguage('node-repl', nodeRepl)
    hljs.registerLanguage('qml', qml)
    hljs.registerLanguage('sqf', sqf)
    hljs.registerLanguage('vbscript', vbscript)
    hljs.registerLanguage('vbscript-html', vbscriptHtml)
    hljs.registerLanguage('vim', vim)
    hljs.registerLanguage('wren', wren)
    hljs.registerLanguage('nim', nim)
    hljs.registerLanguage('django', django)
    hljs.registerLanguage('dust', dust)
    hljs.registerLanguage('erb', erb)
    hljs.registerLanguage('haml', haml)
    hljs.registerLanguage('handlebars', handlebars)
    hljs.registerLanguage('mojolicious', mojolicious)
    hljs.registerLanguage('parser3', parser3)
    hljs.registerLanguage('twig', twig)
    hljs.registerLanguage('apache', apache)
    hljs.registerLanguage('livescript', livescript)
    hljs.registerLanguage('abnf', abnf)
    hljs.registerLanguage('ada', ada)
    hljs.registerLanguage('arduino', arduino)
    hljs.registerLanguage('aspectj', aspectj)
    hljs.registerLanguage('awk', awk)
    hljs.registerLanguage('basic', basic)
    hljs.registerLanguage('bnf', bnf)
    hljs.registerLanguage('brainfuck', brainfuck)
    hljs.registerLanguage('cal', cal)
    hljs.registerLanguage('ceylon', ceylon)
    hljs.registerLanguage('cmake', cmake)
    hljs.registerLanguage('crystal', crystal)
    hljs.registerLanguage('csp', csp)
    hljs.registerLanguage('d', d)
    hljs.registerLanguage('delphi', delphi)
    hljs.registerLanguage('dos', dos)
    hljs.registerLanguage('ebnf', ebnf)
    hljs.registerLanguage('excel', excel)
    hljs.registerLanguage('fix', fix)
    hljs.registerLanguage('gcode', gcode)
    hljs.registerLanguage('gherkin', gherkin)
    hljs.registerLanguage('golo', golo)
    hljs.registerLanguage('gradle', gradle)
    hljs.registerLanguage('groovy', groovy)
    hljs.registerLanguage('haxe', haxe)
    hljs.registerLanguage('inform7', inform7)
    hljs.registerLanguage('julia', julia)
    hljs.registerLanguage('julia-repl', juliaRepl)
    hljs.registerLanguage('lasso', lasso)
    hljs.registerLanguage('leaf', leaf)
    hljs.registerLanguage('mercury', mercury)
    hljs.registerLanguage('monkey', monkey)
    hljs.registerLanguage('n1ql', n1ql)
    hljs.registerLanguage('nix', nix)
    hljs.registerLanguage('nsis', nsis)
    hljs.registerLanguage('oxygene', oxygene)
    hljs.registerLanguage('pgsql', pgsql)
    hljs.registerLanguage('pony', pony)
    hljs.registerLanguage('powershell', powershell)
    hljs.registerLanguage('profile', profile)
    hljs.registerLanguage('prolog', prolog)
    hljs.registerLanguage('purebasic', purebasic)
    hljs.registerLanguage('q', q)
    hljs.registerLanguage('routeros', routeros)
    hljs.registerLanguage('sas', sas)
    hljs.registerLanguage('smali', smali)
    hljs.registerLanguage('smalltalk', smalltalk)
    hljs.registerLanguage('step21', step21)
    hljs.registerLanguage('subunit', subunit)
    hljs.registerLanguage('taggerscript', taggerscript)
    hljs.registerLanguage('tap', tap)
    hljs.registerLanguage('tcl', tcl)
    hljs.registerLanguage('tp', tp)
    hljs.registerLanguage('vala', vala)
    hljs.registerLanguage('verilog', verilog)
    hljs.registerLanguage('vhdl', vhdl)
    hljs.registerLanguage('xl', xl)
    hljs.registerLanguage('zephir', zephir)

    moment.locale('en', {
      calendar: {
        sameDay: '[Today at] LT',
        nextDay: '[Tomorrow at] LT',
        nextWeek: 'dddd [at] LT',
        lastDay: '[Yesterday at] LT',
        lastWeek: '[Last] dddd [at] LT',
        sameElse: 'LLLL'
      }
    })

    moment.locale('es', {
      calendar: {
        lastDay: '[Ayer a las] LT',
        sameDay: '[Hoy a las] LT',
        nextDay: '[Mañana a las] LT',
        lastWeek: '[El] dddd [pasado a las] LT',
        nextWeek: 'dddd [a las] LT',
        sameElse: '[El] LLLL'
      },
      longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'DD/MM/YYYY',
        LL: 'D [de] MMMM [de] YYYY',
        LLL: 'D [de] MMMM [de] YYYY h:mm A',
        LLLL: 'dddd D [de] MMMM [de] YYYY [a las] h:mm A'
      }
    })
  }

  ngAfterContentInit(): void {
    this.loaderService.onDisplayChange((loading) => {
      setTimeout(() => {
        this.performingAction = loading
      })
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const el = document.getElementById('globalLoader')
      if (el) {
        if (!el.classList.contains('global-loader-hidden')) {
          el.className += ' global-loader-fade-in'

          setTimeout(() => {
            document.body.removeChild(el)
          }, 500)
        }
      }
    }, 250)
  }
}
