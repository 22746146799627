/*
Language: Dockerfile
Requires: bash.js
Author: Alexis Hénaut <alexis@henaut.net>
Description: language definition for Dockerfile files
Website: https://docs.docker.com/engine/reference/builder/
Category: config
*/

/** @type LanguageFn */
function dockerfile(hljs) {
  const KEYWORDS = ["from", "maintainer", "expose", "env", "arg", "user", "onbuild", "stopsignal"];
  return {
    name: 'Dockerfile',
    aliases: ['docker'],
    case_insensitive: true,
    keywords: KEYWORDS,
    contains: [hljs.HASH_COMMENT_MODE, hljs.APOS_STRING_MODE, hljs.QUOTE_STRING_MODE, hljs.NUMBER_MODE, {
      beginKeywords: 'run cmd entrypoint volume add copy workdir label healthcheck shell',
      starts: {
        end: /[^\\]$/,
        subLanguage: 'bash'
      }
    }],
    illegal: '</'
  };
}
export { dockerfile as default };