import { APP_INITIALIZER, ErrorHandler, NgModule, inject } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppComponent } from './app.component'
import { Router, RouterModule, Routes } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ToastModule } from 'primeng/toast'
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { AuthGuard } from './core/guards/auth-guard'
import { Provider } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ZerGroshHttpInterceptor } from './core/components/interceptors/ZerGroshHttpInterceptor'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { LoaderService } from './core/services/loader.service'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { QuillModule } from 'ngx-quill'
import * as Sentry from '@sentry/angular'
import { DashboardGuard } from './core/guards/dashboard-guard'
import { Error404Component } from './core/components/errors/error404/error404.component'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { environment } from 'src/environments/environment'
import { RippleModule } from 'primeng/ripple'
import {
  MarkdownModule,
  MARKED_OPTIONS,
  MarkedOptions,
  MarkedRenderer
} from 'ngx-markdown'

const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./core/components/home/home.component').then(
        (c) => c.HomeComponent
      )
  },
  {
    path: 'oauth/login',
    loadComponent: () =>
      import('./core/components/popup/login/login.component').then(
        (c) => c.LoginComponent
      )
  },
  {
    path: 'oauth/guild',
    loadComponent: () =>
      import('./core/components/popup/guild/guild.component').then(
        (c) => c.GuildComponent
      )
  },
  {
    path: '404',
    loadComponent: () =>
      import('./core/components/errors/error404/error404.component').then(
        (c) => c.Error404Component
      )
  },
  {
    path: 'gate/payments/success',
    canActivate: [() => inject(AuthGuard).canActivate()],
    loadComponent: () =>
      import(
        './core/components/gate/payments/success-page/success-page.component'
      )
        .then()
        .then((c) => c.SuccessPageComponent)
  },
  {
    path: 'gate/payments/cancelled',
    canActivate: [() => inject(AuthGuard).canActivate()],
    loadComponent: () =>
      import(
        './core/components/gate/payments/cancelled-page/cancelled-page.component'
      )
        .then()
        .then((c) => c.CancelledPageComponent)
  },
  {
    path: 'me/billing',
    loadComponent: () =>
      import('./core/components/me/billing-page/billing-page.component').then(
        (c) => c.BillingPageComponent
      )
  },
  {
    path: 'me/servers',
    loadComponent: () =>
      import('./core/components/me/servers/servers.component').then(
        (c) => c.ServersComponent
      )
  },
  {
    path: 'me/server/:id',
    loadComponent: () =>
      import('./core/components/me/server/server.component').then(
        (c) => c.ServerComponent
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './core/components/me/server/tab/general/general.component'
          ).then((c) => c.GeneralComponent),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'settings',
        loadComponent: () =>
          import(
            './core/components/me/server/tab/settings/settings.component'
          ).then((c) => c.SettingsComponent),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'zergrosh-ai',
        loadComponent: () =>
          import(
            './core/components/me/server/tab/zergrosh-ai/zergrosh-ai.component'
          ).then((c) => c.ZergroshAiComponent),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'mod/welcome-and-goodbye',
        loadComponent: () =>
          import(
            './core/components/me/server/tab/welcome-and-goodbye/welcome-and-goodbye.component'
          ).then((c) => c.WelcomeAndGoodbyeComponent),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'mod/welcome-channel',
        loadComponent: () =>
          import(
            './core/components/me/server/tab/welcome-channel/welcome-channel.component'
          ).then((c) => c.WelcomeChannelComponent),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'mod/reaction-roles',
        loadComponent: () =>
          import(
            './core/components/me/server/tab/reaction-roles/reaction-roles.component'
          ).then((c) => c.ReactionRolesComponent),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'mod/levels',
        loadComponent: () =>
          import(
            './core/components/me/server/tab/levels/levels.component'
          ).then((c) => c.LevelsComponent),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'mod/music',
        loadComponent: () =>
          import('./core/components/me/server/tab/music/music.component').then(
            (c) => c.MusicComponent
          ),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'mod/birthday',
        loadComponent: () =>
          import(
            './core/components/me/server/tab/birthday/birthday.component'
          ).then((c) => c.BirthdayComponent),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'mod/tiktok',
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()],
        loadComponent: () =>
          import(
            './core/components/me/server/tab/tiktok/tiktok.component'
          ).then((c) => c.TiktokComponent),
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './core/components/me/server/tab/tiktok/tiktok-list/tiktok-list.component'
              ).then((c) => c.TiktokListComponent),
            canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
          },
          {
            path: 'live/add',
            loadComponent: () =>
              import(
                './core/components/me/server/tab/tiktok/tiktok-live-add/tiktok-live-add.component'
              ).then((c) => c.TiktokLiveAddComponent),
            canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
          },
          {
            path: 'live/edit/:idPublic',
            loadComponent: () =>
              import(
                './core/components/me/server/tab/tiktok/tiktok-live-add/tiktok-live-add.component'
              ).then((c) => c.TiktokLiveAddComponent),
            canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
          },
          {
            path: 'video/add',
            loadComponent: () =>
              import(
                './core/components/me/server/tab/tiktok/tiktok-video-add/tiktok-video-add.component'
              ).then((c) => c.TiktokVideoAddComponent),
            canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
          },
          {
            path: 'video/edit/:idPublic',
            loadComponent: () =>
              import(
                './core/components/me/server/tab/tiktok/tiktok-video-add/tiktok-video-add.component'
              ).then((c) => c.TiktokVideoAddComponent),
            canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
          }
        ]
      },
      {
        path: 'mod/twitch',
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()],
        loadComponent: () =>
          import(
            './core/components/me/server/tab/twitch/twitch.component'
          ).then((c) => c.TwitchComponent),
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './core/components/me/server/tab/twitch/twitch-list/twitch-list.component'
              ).then((c) => c.TwitchListComponent),
            canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
          },
          {
            path: 'stream/add',
            loadComponent: () =>
              import(
                './core/components/me/server/tab/twitch/twitch-stream-add/twitch-stream-add.component'
              ).then((c) => c.TwitchStreamAddComponent),
            canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
          },
          {
            path: 'stream/edit/:idPublic',
            loadComponent: () =>
              import(
                './core/components/me/server/tab/twitch/twitch-stream-add/twitch-stream-add.component'
              ).then((c) => c.TwitchStreamAddComponent),
            canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
          }
        ]
      },
      {
        path: 'mod/count',
        loadComponent: () =>
          import('./core/components/me/server/tab/count/count.component').then(
            (c) => c.CountComponent
          ),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'mod/story',
        loadComponent: () =>
          import('./core/components/me/server/tab/story/story.component').then(
            (c) => c.StoryComponent
          ),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      {
        path: 'bot/audit-logs',
        loadComponent: () =>
          import(
            './core/components/me/server/tab/audit-logs/audit-logs.component'
          ).then((c) => c.AuditLogsComponent),
        canDeactivate: [() => inject(DashboardGuard).canDeactivate()]
      },
      { path: '**', redirectTo: '' }
    ]
  },
  {
    path: 'legal/cookies-policy',
    loadComponent: () =>
      import(
        './core/components/legal/cookie-policy/cookie-policy.component'
      ).then((c) => c.CookiePolicyComponent)
  },
  {
    path: 'legal/terms-of-service',
    loadComponent: () =>
      import(
        './core/components/legal/terms-of-service/terms-of-service.component'
      ).then((c) => c.TermsOfServiceComponent)
  },
  {
    path: 'legal/privacy-policy',
    loadComponent: () =>
      import(
        './core/components/legal/privacy-policy/privacy-policy.component'
      ).then((c) => c.PrivacyPolicyComponent)
  },
  { path: '**', component: Error404Component }
]

/** Provider for the Noop Interceptor. */
export const httpProviders: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ZerGroshHttpInterceptor,
  multi: true
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

Sentry.init({
  dsn: 'https://0e413f01284de17692d76e1459aedf76@o4507279136915456.ingest.us.sentry.io/4507280151543808',
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/zergrosh\.gg\/api/],
  beforeSend(event, hint) {
    if (!environment.production) {
      const exception =
        hint.originalException ?? hint.syntheticException ?? undefined
      if (exception !== undefined) {
        console.error('Error no enviado a Sentry')
        console.error(exception)
      }
      return null
    } else {
      return event
    }
  }
})

const initializeAppFactory = (primeConfig: PrimeNGConfig) => () => {
  // ......
  primeConfig.ripple = true
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    }),
    BrowserModule,
    FontAwesomeModule,
    ToastModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    QuillModule.forRoot({}),
    BsDropdownModule.forRoot(),
    RippleModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: () => {
          const renderer = new MarkedRenderer()

          // Disable bold and italic by overriding the respective methods
          renderer.paragraph = (text) => '<span>' + text + '</span>\n'
          renderer.heading = (text, level) => {
            switch (level) {
              case 1:
                return '<h1>' + text + '</h1>'
              case 2:
                return '<h2>' + text + '</h2>'
              case 3:
                return '<h3>' + text + '</h3>'
              case 4:
                return '#### ' + text
              case 5:
                return '##### ' + text
              case 6:
                return '###### ' + text
              case 7:
                return '####### ' + text
              case 8:
                return '######## ' + text
              default:
                return text
            }
          }
          renderer.image = (href, title, text) => {
            return (
              '![' +
              text +
              '](' +
              href +
              (title !== null ? ' "' + title + ' "' : '') +
              ''
            )
          }
          renderer.html = (html) =>
            html
              .replace('&', '&amp')
              .replace('<', '&lt;')
              .replace('>', '&gt;')
              .replace('"', '&quot;')

          return {
            renderer: renderer
          } as MarkedOptions
        }
      }
    })
  ],
  providers: [
    MessageService,
    httpProviders,
    ConfirmationService,
    LoaderService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
        dialogOptions: {
          lang: 'en',
          onClose() {}
        }
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [Sentry.TraceService, PrimeNGConfig],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
